// NOTE: The polyfill works only on the production build at the moment. See https://github.com/facebook/create-react-app/issues/5336
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./components/App";
import * as Sentry from "@sentry/browser";
import config from "./config";
import { Auth0Provider } from "@auth0/auth0-react";

Sentry.init({ dsn: config.sentryDSN, environment: config.appEnv });

ReactDOM.render(
  <Auth0Provider
    authorizationParams={{
      audience: config.auth0AudiencePlatform,
      redirect_uri: config.auth0RedirectUri
    }}
    domain={config.auth0Domain}
    clientId={config.auth0ClientId}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
