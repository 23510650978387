import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik, useField } from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useDispatch } from "react-redux";
import initialValues from "./accessRequestState";
import {
  createAccessRequest,
  createAccessRequestForApproval,
  removeAttachment,
  updateAccessRequest,
  updateAccessRequestForApproval
} from "../../actions/access_request";
import { emptyCart } from "../../actions/cart";
import FileUploader from "../FileUploader/RequestFileUploader";
import Title from "../Title/Title";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import { useTranslation } from "react-i18next";
import {
  AccessRequestSchema,
  SaveAccessRequestSchema
} from "./AccessRequestSchema";
import CustomFormControl from "../CustomFormControl";
import CheckBox from "../CheckBox";
import Radio from "../RadioButton";
import RequestFileList from "../FileUploader/RequestFileList";
import { getAttachmentIdByFilename } from "../../helpers/attachmentHelper";
import { specimenTypeMapping } from "../../helpers/feasibilityRequestMapper";
import { Alert } from "react-bootstrap";
import LoadingWrapper from "../LoadingWrapper";
import { nullsToEmptyStrings } from "../../helpers/formHelper";
import FormFieldError from "../FormFieldError";

import { range } from "lodash";
import CustomFormLabel from "../CustomFormLabel";
import OptionPlaceholder from "../OptionPlaceholder";
import FieldErrorBlock from "../FieldErrorBlock";
import UnsavedChangesPrompt from "../UnsavedChangesPrompt";
import LoadingForm from "../LoadingForm";
import { countryCodeLength } from "../../helpers/formSchemaHelper";
import { maxProjectDescriptionLength } from "../../helpers/formSchemaHelper";
import requestStates from "../../common/requestStates";

const isWaitingForFeedback = initialValues =>
  "state" in initialValues && initialValues.state === requestStates.refine;

const biobanksSelected = (values, biobanks) =>
  values.included_biobanks.some(id => biobanks.includes(id));

const finalValidationSchema = AccessRequestSchema;

const AccessRequestLabel = props => (
  <CustomFormLabel validationSchema={finalValidationSchema} {...props} />
);

const AccessRequestSelect = ({
  children,
  onChange,
  label = true,
  ...props
}) => {
  const { name, disabled } = props;
  const { t } = useTranslation();
  const [field, meta] = useField(props);
  const { touched, error } = meta;

  const onChangeIsFunction = typeof onChange === "function";

  return (
    <>
      {label && <Form.Label>{t(`arForm_label_${name}`)}</Form.Label>}
      <Form.Control
        {...field}
        disabled={disabled}
        as="select"
        name={name}
        {...(onChangeIsFunction ? { onChange: onChange } : {})}
      >
        {children}
      </Form.Control>
      {touched && error && (
        <FieldErrorBlock>{t(error.key, error.values)}</FieldErrorBlock>
      )}
    </>
  );
};

const AccessRequestDatePicker = ({ label, namePrefix, disabled }) => {
  const { t } = useTranslation();
  const months = useMemo(
    () =>
      range(1, 13).map(monthNum => (
        <option
          key={`m${monthNum}`}
          value={monthNum.toString().padStart(2, "0")}
        >
          {t(`datepicker_month_${monthNum}`)}
        </option>
      )),
    [t]
  );

  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const tenYearsAway = currentYear + 20;
    return range(currentYear, tenYearsAway).map(year => (
      <option key={`y${year}`} value={year}>
        {year}
      </option>
    ));
  }, []);

  return (
    <>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Row>
        <Form.Group as={Col}>
          <AccessRequestSelect
            disabled={disabled}
            name={`${namePrefix}month`}
            label={false}
          >
            <OptionPlaceholder>{t("datepicker_choose")}</OptionPlaceholder>
            {months}
          </AccessRequestSelect>
        </Form.Group>
        <Form.Group as={Col}>
          <AccessRequestSelect
            disabled={disabled}
            name={`${namePrefix}year`}
            label={false}
          >
            <OptionPlaceholder>{t("datepicker_choose")}</OptionPlaceholder>
            {years}
          </AccessRequestSelect>
        </Form.Group>
      </Form.Row>
    </>
  );
};

export const DeprecatedAccessRequest = ({
  initialValues,
  isLoading,
  errorPrefilling,
  files,
  id,
  disabled
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isSubmittedForApproval, setSubmittedForApproval] = useState(false);
  const [filesList, setFilesList] = useState(files || []);

  // When access request is created from existing feasibility request, we get
  // the feasibility requests attachments' filenames. This needs to be separate
  // from the file list. File list requires the full file, and these are just the
  // filenames without binary information.
  const [filesFromFeasibility, setFilesFromFeasibility] = useState(
    initialValues.filesFromFeasibility || []
  );

  useEffect(() => {
    setFilesList(files || []);
  }, [files]);

  // The filesFromFeasibility isn't available on first render, so it needs to be
  // wrapped in the useEffect.
  useEffect(() => {
    setFilesFromFeasibility(initialValues.filesFromFeasibility);
  }, [initialValues]);

  const removeFile = fileId => {
    setFilesList(
      filesList.filter(file => (file.attachmentid ?? file.filename) !== fileId)
    );
  };

  const schema = isSubmittedForApproval
    ? AccessRequestSchema
    : SaveAccessRequestSchema;

  const AccessRequestFormControl = props => (
    <CustomFormControl
      {...props}
      validationSchema={schema}
      finalValidationSchema={finalValidationSchema}
      disabled={props.disabled}
    />
  );
  const AccessRequestRadio = props => (
    <Radio {...props} validationSchema={schema} disabled={disabled} />
  );
  const AccessRequestCheckBox = props => (
    <CheckBox {...props} validationSchema={schema} disabled={disabled} />
  );

  const AccessRequestContactBlock = ({
    children,
    prefix,
    disabled = false
  }) => {
    const genId = fieldName => "form" + prefix.toUpperCase() + fieldName;
    const genName = fieldName => prefix.toLowerCase() + "_" + fieldName;

    return (
      <>
        <Form.Row>
          <Form.Group as={Col} controlId={genId("firstname")}>
            <AccessRequestFormControl
              name={genName("firstname")}
              label={t("firstname")}
              maxLength={40}
              disabled={disabled}
            />
          </Form.Group>

          <Form.Group as={Col} controlId={genId("lastname")}>
            <AccessRequestFormControl
              name={genName("surname")}
              label={t("lastname")}
              maxLength={50}
              disabled={disabled}
            />
          </Form.Group>
        </Form.Row>
        <Form.Group controlId={genId("email")}>
          <AccessRequestFormControl
            type="email"
            name={genName("email")}
            label={t("email")}
            maxLength={100}
            disabled={disabled}
          />
        </Form.Group>
        <Form.Group controlId={genId("company")}>
          <AccessRequestFormControl
            name={genName("company")}
            label={t("organization")}
            maxLength={100}
            disabled={disabled}
          />
        </Form.Group>
        <Form.Group controlId={genId("phone")}>
          <AccessRequestFormControl
            type={"tel"}
            name={genName("phone")}
            label={t("phone")}
            maxLength={30}
            disabled={disabled}
          />
        </Form.Group>
        <Form.Group controlId={genId("address")}>
          <AccessRequestFormControl
            name={genName("address")}
            label={t("address")}
            maxLength={100}
            disabled={disabled}
          />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId={genId("countrycode")}>
            <AccessRequestFormControl
              name={genName("countrycd")}
              label={t("country_code", { countryCodeLength })}
              length={countryCodeLength}
              disabled={disabled}
            />
          </Form.Group>

          <Form.Group as={Col} controlId={genId("zip")}>
            <AccessRequestFormControl
              name={genName("zip")}
              label={t("zip")}
              maxLength={10}
              disabled={disabled}
            />
          </Form.Group>

          <Form.Group as={Col} controlId={genId("city")}>
            <AccessRequestFormControl
              name={genName("city")}
              label={t("city")}
              maxLength={40}
              disabled={disabled}
            />
          </Form.Group>
        </Form.Row>
        {children}
      </>
    );
  };

  const errorBox = isWaitingForFeedback(initialValues) && (
    <Alert variant="danger">{t("frform_error_returned_for_more")}</Alert>
  );

  const formRef = useRef(null);

  const handleClick = (event, handleSubmit, isSubmittedForApproval) => {
    setTimeout(() => {
      formRef.current.checkValidity();
      formRef.current.reportValidity();
    }, 0);
    setTimeout(handleSubmit, 0);
    event.preventDefault();
    setSubmittedForApproval(isSubmittedForApproval);
  };

  const biobankMandatoryDocumentsText = values => {
    // show the study protocol for ALL biobanks as a mandatory doc
    const strs = [t("arForm_mandatory_documents_study_protocol")];

    if (biobanksSelected(values, ["10"])) {
      strs.push(t("arForm_mandatory_documents_data_processing_assessment"));
    }

    if (biobanksSelected(values, ["20"])) {
      strs.push(t("arForm_mandatory_documents_ethics_committee_statement"));
    }

    if (biobanksSelected(values, ["30", "50", "60"])) {
      strs.push(
        t("arForm_mandatory_documents_principle_investigator_cv"),
        t("arForm_mandatory_documents_data_processing_assessment")
      );
    }
    const uniqueStrs = [...new Set(strs)];
    return uniqueStrs;
  };

  const onSubmit = async values => {
    let submit;
    values.filesFromFeasibility = JSON.stringify(filesFromFeasibility);
    if (isSubmittedForApproval) {
      submit = dispatch(
        id
          ? updateAccessRequestForApproval(id, values)
          : createAccessRequestForApproval(values)
      );
    } else {
      submit = dispatch(
        id ? updateAccessRequest(id, values) : createAccessRequest(values)
      );
    }
    await submit;
    // empty the cart now
    dispatch(emptyCart());
  };

  return (
    <>
      <Title>{t("access_request")}</Title>
      <HorizontalLine className={"double-vertical-margin-bottom"} />
      <LoadingWrapper
        isLoading={isLoading}
        isInErrorState={errorPrefilling}
        errorMsg={t("form_api_offline")}
        loadingComponent={<LoadingForm />}
      >
        <Formik
          enableReinitialize={true}
          initialValues={nullsToEmptyStrings(initialValues)}
          validationSchema={schema}
          validateOnChange={false}
          onSubmit={onSubmit}
        >
          {formProps => (
            <Form onSubmit={formProps.handleSubmit} ref={formRef}>
              <UnsavedChangesPrompt isEnabled={formProps.dirty} />
              <Container>
                {errorBox}
                <Row>
                  <Col lg={4}>
                    <h2>{t("biobanks")}</h2>
                    <p className={"section-description"}>
                      {t("biobanks_description")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <Form.Group
                      id="formIncludedBiobanks"
                      controlId={"formIncludedBiobanks"}
                    >
                      <AccessRequestLabel
                        withErrorIndicator
                        formProps={formProps}
                        fieldName={"included_biobanks"}
                      >
                        {t("choose_biobanks_access_request")}
                      </AccessRequestLabel>
                      <AccessRequestCheckBox
                        label={t("arctic_biobank")}
                        name={"included_biobanks"}
                        value={"90"}
                      />
                      <AccessRequestCheckBox
                        label={t("auria_biobank")}
                        name={"included_biobanks"}
                        value={"10"}
                      />
                      <AccessRequestCheckBox
                        label={t("helsinki_biobank")}
                        name={"included_biobanks"}
                        value={"20"}
                      />
                      <AccessRequestCheckBox
                        label={t("biobank_of_eastern_finland")}
                        name={"included_biobanks"}
                        value={"30"}
                      />
                      <AccessRequestCheckBox
                        label={t("central_finland_biobank")}
                        name={"included_biobanks"}
                        value={"40"}
                      />
                      <AccessRequestCheckBox
                        label={t("northern_finland_biobank_borealis")}
                        name={"included_biobanks"}
                        value={"50"}
                      />
                      <AccessRequestCheckBox
                        label={t("tampere_biobank")}
                        name={"included_biobanks"}
                        value={"60"}
                      />
                      <AccessRequestCheckBox
                        label={t("thl_biobank")}
                        name={"included_biobanks"}
                        value={"80"}
                      />
                    </Form.Group>
                  </Col>
                  <HorizontalLine className={"double-vertical-margin"} />
                  <Col lg={4}>
                    <h2>{t("arForm_heading_research_project")}</h2>
                    <p className={"section-description"}>
                      {t("arForm_heading_research_project_desc")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <Form.Group id="formProjectType">
                      <AccessRequestLabel
                        withErrorIndicator
                        formProps={formProps}
                        fieldName={"projecttype"}
                      >
                        {t("arForm_projecttype_label")}
                      </AccessRequestLabel>
                      <AccessRequestRadio
                        label={t("arForm_projecttype_only_data")}
                        name={"projecttype"}
                        value={"10"}
                      />
                      <AccessRequestRadio
                        label={t("arForm_projecttype_only_samples")}
                        name={"projecttype"}
                        value={"20"}
                      />
                      <AccessRequestRadio
                        label={t("arForm_projecttype_samples_and_data")}
                        name={"projecttype"}
                        value={"30"}
                      />
                      <AccessRequestFormControl name={"projecttype"} empty />
                    </Form.Group>
                    <Form.Group controlId="formProjectName">
                      <AccessRequestFormControl
                        name={"projektin_nimi"}
                        label={t("arForm_projektin_nimi_label")}
                        maxLength={500}
                      />
                    </Form.Group>
                    <Form.Group controlId="formProjectShortName">
                      <AccessRequestFormControl
                        name={"project_short_name"}
                        label={t("arForm_project_short_name_label")}
                        maxLength={100}
                      />
                    </Form.Group>
                    <Form.Group controlId="formStartMonthYear">
                      <AccessRequestDatePicker
                        disabled={disabled}
                        namePrefix="starting_"
                        label={t("arForm_starting_monthyear_label")}
                      />
                    </Form.Group>
                    <Form.Group controlId="formStartMonthYear">
                      <AccessRequestDatePicker
                        disabled={disabled}
                        namePrefix="ending_"
                        label={t("arForm_ending_monthyear_label")}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBriefDescription">
                      <AccessRequestFormControl
                        as={"textarea"}
                        name={"brief_project_desc"}
                        label={t("arForm_brief_project_desc_label", {
                          maxProjectDescriptionLength
                        })}
                        maxLength={maxProjectDescriptionLength}
                        rows={3}
                      />
                    </Form.Group>
                  </Col>
                  <HorizontalLine className={"double-vertical-margin"} />
                  <Col lg={4}>
                    <h2>{t("contact_person")}</h2>
                    <p className={"section-description"}>
                      {t("contact_person_description")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <AccessRequestContactBlock prefix="c" disabled={true} />
                  </Col>
                  <HorizontalLine className={"double-vertical-margin"} />
                  <Col lg={4}>
                    <h2>{t("background_organization")}</h2>
                    <p className={"section-description"}>
                      {t("background_organization_description")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <AccessRequestContactBlock prefix="bg">
                      <Form.Group controlId="formBgBusinessId">
                        <AccessRequestFormControl
                          name={"bg_businessid"}
                          label={t("arForm_business_id_label")}
                          maxLength={50}
                        />
                      </Form.Group>
                    </AccessRequestContactBlock>
                  </Col>
                  <HorizontalLine className={"double-vertical-margin"} />
                  <Col lg={4}>
                    <h2>{t("arForm_heading_person_charge")}</h2>
                    <p className={"section-description"}>
                      {t("arForm_heading_person_charge_desc")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <AccessRequestContactBlock prefix="r" />
                  </Col>
                  <HorizontalLine className={"double-vertical-margin"} />
                  <Col lg={4}>
                    <h2>{t("arForm_heading_public_summary")}</h2>
                    <p className={"section-description"}>
                      {t("arForm_heading_public_summary_desc")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <Form.Row>
                      <Form.Group controlId="formSummaryEnglish">
                        <AccessRequestFormControl
                          as={"textarea"}
                          name={"project_desc"}
                          label={t("arForm_project_desc_label")}
                          maxLength={1000}
                          rows={3}
                        />
                      </Form.Group>
                      <Form.Group controlId="formSummaryFinnish">
                        <AccessRequestFormControl
                          as={"textarea"}
                          name={"project_summary"}
                          label={t("arForm_project_summary_label")}
                          maxLength={1000}
                          rows={3}
                        />
                      </Form.Group>
                    </Form.Row>
                  </Col>
                  <HorizontalLine className={"double-vertical-margin"} />
                  <Col lg={4}>
                    <h2>{t("arForm_heading_samples_description")}</h2>
                    <p className={"section-description"}>
                      {t("arForm_heading_samples_description_desc")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <Form.Group id="formSampleTypes">
                      <AccessRequestLabel
                        withErrorIndicator
                        formProps={formProps}
                        fieldName={"sample_types"}
                      >
                        {t("what_kind_of_samples")}
                      </AccessRequestLabel>
                      <AccessRequestCheckBox
                        label={t("ffpe_tissue")}
                        name={"sample_types"}
                        value={"10"}
                      />
                      <AccessRequestCheckBox
                        label={t("tma_slides_block")}
                        name={"sample_types"}
                        value={"20"}
                      />
                      <AccessRequestCheckBox
                        label={t("fresh_frozen_tissue")}
                        name={"sample_types"}
                        value={"30"}
                      />
                      <AccessRequestCheckBox
                        label={t("data_title_wholeblood")}
                        name={"sample_types"}
                        value={specimenTypeMapping.wholeblood}
                      />
                      <AccessRequestCheckBox
                        label={t("data_title_serum")}
                        name={"sample_types"}
                        value={specimenTypeMapping.serum}
                      />
                      <AccessRequestCheckBox
                        label={t("data_title_plasma")}
                        name={"sample_types"}
                        value={specimenTypeMapping.plasma}
                      />
                      <AccessRequestCheckBox
                        label={t("data_title_buffycoat")}
                        name={"sample_types"}
                        value={specimenTypeMapping.buffycoat}
                      />
                      <AccessRequestCheckBox
                        label={t("data_title_urine")}
                        name={"sample_types"}
                        value={specimenTypeMapping.urine}
                      />
                      <AccessRequestCheckBox
                        label={t("data_title_dna")}
                        name={"sample_types"}
                        value={specimenTypeMapping.dna}
                      />
                      <AccessRequestCheckBox
                        label={t("ct_dna")}
                        name={"sample_types"}
                        value={"91"}
                      />
                      <AccessRequestCheckBox
                        label={t("digitized_scanned_slides")}
                        name={"sample_types"}
                        value={"92"}
                      />
                      <AccessRequestCheckBox
                        label={t("genome_data")}
                        name={"sample_types"}
                        value={specimenTypeMapping.genome_data}
                      />
                      <AccessRequestFormControl
                        as={"textarea"}
                        name={"sequence_data_desc"}
                      />
                      <AccessRequestCheckBox
                        label={t("any_other")}
                        name={"sample_types"}
                        value={"122"}
                      />
                      <AccessRequestFormControl
                        as={"textarea"}
                        name={"other_sample_type"}
                        maxLength={100}
                      />
                    </Form.Group>

                    <Form.Group controlId="formExactDesc">
                      <AccessRequestFormControl
                        as={"textarea"}
                        name={"exact_desc"}
                        label={t("arForm_exact_desc_label")}
                        maxLength={5000}
                      />
                    </Form.Group>
                    <Form.Group controlId="formRequirements">
                      <AccessRequestFormControl
                        as={"textarea"}
                        name={"requirements"}
                        label={t("arForm_requirements_label")}
                        maxLength={5000}
                      />
                    </Form.Group>
                    <Form.Group controlId="formSpecialRequirements">
                      <AccessRequestFormControl
                        as={"textarea"}
                        name={"special_requirements"}
                        label={t("arForm_special_requirements_label")}
                        maxLength={5000}
                      />
                    </Form.Group>
                    <p>{t("arForm_further_information_contact_label")}</p>
                    <AccessRequestContactBlock prefix="a" />
                  </Col>
                  <HorizontalLine className={"double-vertical-margin"} />
                  <Col lg={4}>
                    <h2>{t("arForm_data_description_summary")}</h2>
                    <p className={"section-description"}>
                      {t("arForm_data_description_summary_desc")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <Form.Group controlId="formExactDescData">
                      <AccessRequestFormControl
                        as={"textarea"}
                        name={"exact_desc_data"}
                        label={t("arForm_exact_desc_data_label")}
                        maxLength={5000}
                      />
                    </Form.Group>

                    <Form.Group id="formWhoCollects_data">
                      <AccessRequestLabel
                        withErrorIndicator
                        formProps={formProps}
                        fieldName={"who_collects_data"}
                      >
                        {t("arForm_who_collects_data_label")}
                      </AccessRequestLabel>
                      <AccessRequestRadio
                        label={t("arForm_who_collects_data_team")}
                        name={"who_collects_data"}
                        value={"10"}
                      />
                      <AccessRequestRadio
                        label={t("arForm_who_collects_data_biobanks")}
                        name={"who_collects_data"}
                        value={"20"}
                      />
                      <AccessRequestRadio
                        label={t("arForm_who_collects_data_both")}
                        name={"who_collects_data"}
                        value={"30"}
                      />
                    </Form.Group>
                    <Form.Group controlId="formWhoCollectsDataDesc">
                      <AccessRequestFormControl
                        as={"textarea"}
                        name={"who_collects_data_desc"}
                        label={""}
                      />
                    </Form.Group>
                    <p>{t("arForm_who_collects_data_desc_label_post")}</p>
                  </Col>
                  <HorizontalLine className={"double-vertical-margin"} />
                  <Col lg={4}>
                    <h2>{t("arForm_pathologist_resources_summary")}</h2>
                    <p className={"section-description"}>
                      {t("arForm_pathologist_resources_summary_desc")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <p>{t("arForm_pathologist_resources_summary_pretext")}</p>
                    <Form.Group id="formPathologistChoice">
                      <AccessRequestCheckBox
                        label={t("arForm_exact_desc_data_isauriapato")}
                        name={"isauriapato"}
                        value={"T"}
                      />
                      <AccessRequestCheckBox
                        label={t(
                          "arForm_exact_desc_data_arForm_exact_desc_data_label"
                        )}
                        name={"cotykssapapato"}
                        value={"T"}
                      />
                    </Form.Group>
                    <p>{t("arForm_pathologist_contact_label")}</p>
                    <AccessRequestContactBlock prefix="p" />
                  </Col>
                  <HorizontalLine className={"double-vertical-margin"} />
                  <Col lg={4}>
                    <h2>{t("arForm_results_data")}</h2>
                    <p className={"section-description"}>
                      {t("arForm_results_data_desc")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <AccessRequestDatePicker
                      disabled={disabled}
                      namePrefix="est_data_return_"
                      label={t("arForm_estimated_return_label")}
                    />
                    <Form.Group controlId="formWhatResults">
                      <AccessRequestFormControl
                        as={"textarea"}
                        name={"what_results_returns"}
                        label={t("arForm_what_results_returns_label")}
                        maxLength={2500}
                      />
                    </Form.Group>
                    <Form.Group id="formRelevantFindingsFound">
                      <AccessRequestLabel
                        withErrorIndicator
                        formProps={formProps}
                        fieldName={"relevant_findings_found"}
                      >
                        {t("arForm_relevant_findings_label")}
                      </AccessRequestLabel>
                      <AccessRequestRadio
                        label={t("arForm_relevant_findings_found_no")}
                        name={"relevant_findings_found"}
                        value={"10"}
                      />
                      <AccessRequestRadio
                        label={t("arForm_relevant_findings_found_yes")}
                        name={"relevant_findings_found"}
                        value={"20"}
                      />
                    </Form.Group>
                    <p>
                      <strong>{t("arForm_what_findings_title")}</strong>
                    </p>
                    <p>{t("arForm_what_findings_desc")}</p>
                    <Form.Group controlId="formFindings">
                      <AccessRequestFormControl
                        as={"textarea"}
                        name={"what_findings"}
                        label={t("arForm_what_findings_label")}
                        maxLength={500}
                      />
                    </Form.Group>
                  </Col>
                  <HorizontalLine className={"double-vertical-margin"} />
                  <Col lg={4}>
                    <h2>{t("arForm_invoicing_address")}</h2>
                    <p className={"section-description"}>
                      {t("arForm_invoicing_address_desc")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <Form.Group controlId="formInvoicingMethod">
                      <AccessRequestSelect
                        disabled={disabled}
                        name="invoicing_method"
                      >
                        <OptionPlaceholder>
                          {t("default_dropdown_method_choose")}
                        </OptionPlaceholder>
                        <option value="10">
                          {t("arForm_invoicing_method_paper")}
                        </option>
                        <option value="20">
                          {t("arForm_invoicing_method_email")}
                        </option>
                        <option value="30">
                          {t("arForm_invoicing_method_einvoice")}
                        </option>
                      </AccessRequestSelect>
                    </Form.Group>
                    {formProps.values.invoicing_method && (
                      <>
                        <Form.Group controlId="formICompany">
                          <AccessRequestFormControl
                            name={"i_company"}
                            label={t("arForm_i_company_label")}
                            maxLength={100}
                          />
                        </Form.Group>
                        <Form.Group controlId="formIAddress">
                          <AccessRequestFormControl
                            name={"i_address"}
                            label={t("arForm_i_address_label")}
                            maxLength={100}
                          />
                        </Form.Group>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formICountryInfo">
                            <AccessRequestFormControl
                              name="i_countrycd"
                              label={t("country_code", {
                                countryCodeLength
                              })}
                              length={countryCodeLength}
                            />
                          </Form.Group>

                          <Form.Group as={Col} controlId="formIZip">
                            <AccessRequestFormControl
                              name="i_zip"
                              label={t("zip")}
                              maxLength={10}
                            />
                          </Form.Group>

                          <Form.Group as={Col} controlId="formICity">
                            <AccessRequestFormControl
                              name="i_city"
                              label={t("city")}
                              maxLength={40}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Group controlId="formIBusinessId">
                          <AccessRequestFormControl
                            name={"i_businessid"}
                            label={t("arForm_i_businessid_label")}
                            maxLength={50}
                          />
                        </Form.Group>
                        <Form.Group controlId="formIReference">
                          <AccessRequestFormControl
                            name={"i_reference"}
                            label={t("arForm_i_reference_label")}
                            maxLength={200}
                          />
                        </Form.Group>
                      </>
                    )}
                    {formProps.values.invoicing_method === "20" && (
                      <>
                        <Form.Group controlId="formIEmail">
                          <AccessRequestFormControl
                            name={"i_email"}
                            label={t("arForm_i_email_label")}
                            maxLength={100}
                          />
                        </Form.Group>
                      </>
                    )}
                    {formProps.values.invoicing_method === "30" && (
                      <>
                        <Form.Group controlId="formIInvaddress">
                          <AccessRequestFormControl
                            name={"i_invaddress"}
                            label={t("arForm_i_invaddress_label")}
                            maxLength={100}
                          />
                        </Form.Group>
                        <Form.Group controlId="formIOperator">
                          <AccessRequestFormControl
                            name={"i_operator"}
                            label={t("arForm_i_operator_label")}
                            maxLength={50}
                          />
                        </Form.Group>
                        <Form.Group controlId="formIBroker">
                          <AccessRequestFormControl
                            name={"i_broker"}
                            label={t("arForm_i_broker_label")}
                            maxLength={50}
                          />
                        </Form.Group>
                      </>
                    )}
                  </Col>
                  {!biobanksSelected(formProps.values, ["20"]) && (
                    <>
                      <HorizontalLine className={"double-vertical-margin"} />
                      <Col lg={4}>
                        <h2>{t("arForm_researchers")}</h2>
                        <p className={"section-description"}>
                          {t("arForm_researchers_desc")}
                        </p>
                      </Col>
                      <Col lg={8}>
                        <AccessRequestFormControl
                          as={"textarea"}
                          name={"researchers"}
                          label={t("arForm_brief_project_desc_label", {
                            maxProjectDescriptionLength
                          })}
                          maxLength={maxProjectDescriptionLength}
                          rows={3}
                        />
                      </Col>

                      <HorizontalLine className={"double-vertical-margin"} />
                      <Col lg={4}>
                        <h2>{t("arForm_scientific_rationale")}</h2>
                        <p className={"section-description"}>
                          {t("arForm_scientific_rationale_desc")}
                        </p>
                      </Col>
                      <Col lg={8}>
                        <Form.Group id="formWhoCollects_data">
                          <AccessRequestLabel
                            withErrorIndicator
                            formProps={formProps}
                            fieldName={"ethicscommitteestatementavailable"}
                          >
                            {t(
                              "arForm_ethicscommitteestatementavailable_label"
                            )}
                          </AccessRequestLabel>
                          <AccessRequestRadio
                            label={t(
                              "arForm_ethicscommitteestatementavailable_no"
                            )}
                            name={"ethicscommitteestatementavailable"}
                            value={"F"}
                          />
                          <AccessRequestRadio
                            label={t(
                              "arForm_ethicscommitteestatementavailable_yes"
                            )}
                            name={"ethicscommitteestatementavailable"}
                            value={"T"}
                          />
                        </Form.Group>
                        <Form.Group controlId="formScienceProfit">
                          <AccessRequestFormControl
                            as={"textarea"}
                            name={"scienceprofit"}
                            label={t("arForm_scienceprofit_label")}
                            maxLength={2500}
                          />
                        </Form.Group>
                        <Form.Group controlId="formEthicalEvaluation">
                          <AccessRequestFormControl
                            as={"textarea"}
                            name={"ethical_evaluation"}
                            label={t("arForm_ethical_evaluation_label")}
                          />
                        </Form.Group>
                        <Form.Group controlId="formResearchArguments">
                          <AccessRequestFormControl
                            as={"textarea"}
                            name={"research_arguments"}
                            label={t("arForm_research_arguments_label")}
                            maxLength={2500}
                          />
                        </Form.Group>
                      </Col>

                      <HorizontalLine className={"double-vertical-margin"} />
                      <Col lg={4}>
                        <h2>{t("arForm_benefit_risk")}</h2>
                        <p className={"section-description"}>
                          {t("arForm_benefit_risk_desc")}
                        </p>
                      </Col>
                      <Col lg={8}>
                        <Form.Group id="formExpectedHealthBenefit">
                          <AccessRequestLabel
                            withErrorIndicator
                            formProps={formProps}
                            fieldName={"hashealthbenefits"}
                          >
                            {t("arForm_hashealthbenefits_label")}
                          </AccessRequestLabel>
                          <AccessRequestRadio
                            label={t("arForm_hashealthbenefits_no")}
                            name={"hashealthbenefits"}
                            value={"F"}
                          />
                          <AccessRequestRadio
                            label={t("arForm_hashealthbenefits_yes")}
                            name={"hashealthbenefits"}
                            value={"T"}
                          />
                        </Form.Group>
                        <Form.Group controlId="formHealthBenefits">
                          <AccessRequestFormControl
                            as={"textarea"}
                            name={"healthbenefits"}
                            label={t("arForm_healthbenefits_label")}
                            maxLength={2500}
                          />
                        </Form.Group>
                        <p>
                          <strong>
                            {t("arForm_handling_of_incidental_findings_label")}
                          </strong>
                        </p>
                        <p>
                          {t("arForm_handling_of_incidental_findings_desc")}
                        </p>
                        <Form.Group controlId="formAccidentalFindings">
                          <AccessRequestFormControl
                            as={"textarea"}
                            name={"accidental_findings"}
                            label={t("arForm_accidental_findings_label")}
                            maxLength={2500}
                          />
                        </Form.Group>
                        <Form.Group id="formHasRisks">
                          <AccessRequestLabel
                            withErrorIndicator
                            formProps={formProps}
                            fieldName={"hasrisks"}
                          >
                            {t("arForm_hasrisks_label")}
                          </AccessRequestLabel>
                          <AccessRequestRadio
                            label={t("arForm_hasrisks_no")}
                            name={"hasrisks"}
                            value={"F"}
                          />
                          <AccessRequestRadio
                            label={t("arForm_hasrisks_yes")}
                            name={"hasrisks"}
                            value={"T"}
                          />
                        </Form.Group>
                        <Form.Group controlId="formRisks">
                          <AccessRequestFormControl
                            as={"textarea"}
                            name={"risks"}
                            label={t("arForm_risks_label")}
                            maxLength={2500}
                          />
                        </Form.Group>
                      </Col>
                      <HorizontalLine className={"double-vertical-margin"} />
                      <Col lg={4}>
                        <h2>{t("arForm_other_ethical_aspects")}</h2>
                        <p className={"section-description"}>
                          {t("arForm_other_ethical_aspects_desc")}
                        </p>
                      </Col>
                      <Col lg={8}>
                        <Form.Group controlId="formLinkages">
                          <AccessRequestFormControl
                            as={"textarea"}
                            name={"linkages"}
                            label={t("arForm_linkages_label")}
                            maxLength={2500}
                          />
                        </Form.Group>
                        <Form.Group controlId="formMainFunding">
                          <AccessRequestFormControl
                            as={"textarea"}
                            name={"mainfunding"}
                            label={t("arForm_mainfunding_label")}
                            maxLength={2500}
                          />
                        </Form.Group>
                      </Col>
                    </>
                  )}
                  <HorizontalLine className={"double-vertical-margin"} />
                  <Col lg={4}>
                    <h2>{t("arForm_publication_plan")}</h2>
                    <p className={"section-description"}>
                      {t("arForm_publication_plan_desc")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <Form.Group controlId="formPubPlan">
                      <AccessRequestFormControl
                        as={"textarea"}
                        name={"research_result_publish_desc"}
                        label={t("arForm_research_result_publish_desc_label")}
                      />
                    </Form.Group>
                  </Col>
                  <HorizontalLine className={"double-vertical-margin"} />
                  <Col lg={4}>
                    <h2>{t("attachments")}</h2>
                    <p className={"section-description"}>
                      {t("attachments_description")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    {id && (
                      <RequestFileList
                        disabled={disabled}
                        files={filesList.map(file => ({
                          name: file.filename,
                          size: file.filesize
                            ? parseInt(file.filesize)
                            : undefined,
                          url: file.path
                        }))}
                        handleRemoveFile={async fileName => {
                          const fileId = await dispatch(
                            removeAttachment(
                              getAttachmentIdByFilename(filesList, fileName),
                              id
                            )
                          );
                          if (fileId) {
                            removeFile(fileId);
                          }
                        }}
                      />
                    )}
                    {!disabled && (
                      <FileUploader
                        initialFilenames={filesFromFeasibility}
                        onFilesFromFeasibilityChange={newFilenames =>
                          setFilesFromFeasibility(newFilenames)
                        }
                        onDrop={acceptedFiles => {
                          if (acceptedFiles.length === 0) {
                            return;
                          }
                          formProps.setFieldValue("files", acceptedFiles);
                        }}
                      />
                    )}
                  </Col>

                  <>
                    <HorizontalLine className={"double-vertical-margin"} />
                    <Col lg={4}>
                      <h2>{t("arForm_mandatory_documents")}</h2>
                      <p className={"section-description"}>
                        {t("arForm_mandatory_documents_desc")}
                      </p>
                    </Col>
                    <Col lg={8}>
                      <Form.Group id="formMandatoryDocuments">
                        <AccessRequestCheckBox
                          label={t(
                            "arForm_mandatory_documents_required_checkbox_label"
                          )}
                          name={"mandatory_documents"}
                          value={"T"}
                        />
                        <FormFieldError
                          fieldName={"mandatory_documents"}
                          formProps={formProps}
                        />
                      </Form.Group>
                      <p>
                        {biobankMandatoryDocumentsText(formProps.values)
                          .map((t, i) => (
                            <span
                              key={i}
                              dangerouslySetInnerHTML={{ __html: t }}
                            />
                          ))
                          .reduce((prev, curr, idx) => [
                            prev,
                            <br key={`br{$idx}`} />,
                            curr
                          ])}
                      </p>
                    </Col>
                  </>
                </Row>
              </Container>

              <HorizontalLine className={"top-margin"} />
              {!disabled && (
                <Container className="form-submit-row">
                  <Row>
                    <Col lg={{ span: 8, offset: 4 }}>
                      <div className={"button-group"}>
                        <button
                          className={"button button-secondary"}
                          type="submit"
                          disabled={formProps.isSubmitting}
                          onClick={event =>
                            handleClick(event, formProps.handleSubmit, false)
                          }
                        >
                          {t("save_and_continue_later")}
                        </button>
                        <button
                          disabled={formProps.isSubmitting}
                          className={"button"}
                          type="submit"
                          onClick={event =>
                            handleClick(event, formProps.handleSubmit, true)
                          }
                        >
                          {t("submit_for_approval")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              )}
            </Form>
          )}
        </Formik>
      </LoadingWrapper>
    </>
  );
};

DeprecatedAccessRequest.defaultProps = {
  initialValues
};

export default DeprecatedAccessRequest;
