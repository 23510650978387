import initialValues from "./accessRequestState";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  AccessRequestSchema,
  SaveAccessRequestSchema
} from "./AccessRequestSchema";
import CustomFormControl from "../CustomFormControl";
import Radio from "../RadioButton";
import CheckBox from "../CheckBox";
import Title from "../Title/Title";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import LoadingForm from "../LoadingForm";
import LoadingWrapper from "../LoadingWrapper";
import { nullsToEmptyStrings } from "../../helpers/formHelper";
import {
  createAccessRequest,
  createAccessRequestForApproval,
  updateAccessRequest,
  updateAccessRequestForApproval
} from "../../actions/access_request";
import { emptyCart } from "../../actions/cart";
import { Formik, useField } from "formik";
import UnsavedChangesPrompt from "../UnsavedChangesPrompt";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Alert } from "react-bootstrap";
import requestStates from "../../common/requestStates";
import Form from "react-bootstrap/Form";
import { ContactInfoSection } from "../SharedFormSections/ContactInfoSection";
import { BackgroundOrgSection } from "../SharedFormSections/BackgroundOrgSection";
import CustomFormLabel from "../CustomFormLabel";
import { BioBanksSection } from "../SharedFormSections/BioBanksSection";
import { ProjectDetailsSection } from "../SharedFormSections/ProjectDetailsSection";
import { MaterialSection } from "../SharedFormSections/MaterialSection";
import { LiquidSamplesSection } from "../SharedFormSections/LiquidSamplesSection";
import { TissueSamplesSection } from "../SharedFormSections/TissueSamplesSection";
import { PhenotypeLifestyleSection } from "../SharedFormSections/PhenotypeLifestyleSection";
import { GenotypeSection } from "../SharedFormSections/GenotypeSection";
import { RecruitSection } from "../SharedFormSections/RecruitSection";
import { range } from "lodash";
import OptionPlaceholder from "../OptionPlaceholder";
import FieldErrorBlock from "../FieldErrorBlock";
import { ReturnResultsDataSection } from "./Sections/ReturnResultsDataSection";
import { InvoiceDetailsSection } from "./Sections/InvoiceDetailsSection";
import { EthicalMattersSection } from "./Sections/EthicalMattersSection";
import { MandatoryDocumentsSection } from "./Sections/MandatoryDocumentsSection";
import { cleanPropsForNonSelectedSections } from "../../helpers/accessRequestFormHelper";

const isWaitingForFeedback = initialValues =>
  "state" in initialValues && initialValues.state === requestStates.refine;

export const AccessRequestForm = ({
  initialValues,
  isLoading,
  errorPrefilling,
  id,
  disabled
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isSubmittedForApproval, setSubmittedForApproval] = useState(false);

  const formRef = useRef(null);

  // When access request is created from existing feasibility request, we get
  // the feasibility requests attachments' filenames. This needs to be separate
  // from the file list. File list requires the full file, and these are just the
  // filenames without binary information.
  const [filesFromFeasibility, setFilesFromFeasibility] = useState(
    initialValues.filesFromFeasibility || []
  );

  // The filesFromFeasibility isn't available on first render, so it needs to be
  // wrapped in the useEffect.
  useEffect(() => {
    setFilesFromFeasibility(initialValues.filesFromFeasibility);
  }, [initialValues]);

  const schema = isSubmittedForApproval
    ? AccessRequestSchema
    : SaveAccessRequestSchema;

  const AccessRequestFormControl = props => (
    <CustomFormControl
      {...props}
      validationSchema={schema}
      finalValidationSchema={AccessRequestSchema}
      disabled={props.disabled}
    />
  );

  const AccessRequestLabel = props => (
    <CustomFormLabel validationSchema={AccessRequestSchema} {...props} />
  );

  const AccessRequestRadio = props => (
    <Radio {...props} validationSchema={schema} disabled={disabled} />
  );

  const AccessRequestSwitch = props => (
    <Radio
      type="switch"
      disabled={disabled}
      {...props}
      validationSchema={schema}
    />
  );

  const AccessRequestCheckBox = props => (
    <CheckBox {...props} validationSchema={schema} disabled={disabled} />
  );

  const AccessRequestSelect = ({
    children,
    onChange,
    label = true,
    ...props
  }) => {
    const { name, disabled } = props;
    const { t } = useTranslation();
    const [field, meta] = useField(props);
    const { touched, error } = meta;

    const onChangeIsFunction = typeof onChange === "function";

    return (
      <>
        {label && <Form.Label>{t(`arForm_label_${name}`)}</Form.Label>}
        <Form.Control
          {...field}
          disabled={disabled}
          as="select"
          name={name}
          {...(onChangeIsFunction ? { onChange: onChange } : {})}
        >
          {children}
        </Form.Control>
        {touched && error && (
          <FieldErrorBlock>{t(error.key, error.values)}</FieldErrorBlock>
        )}
      </>
    );
  };

  const AccessRequestDatePicker = ({ label, namePrefix, disabled }) => {
    const { t } = useTranslation();
    const months = useMemo(
      () =>
        range(1, 13).map(monthNum => (
          <option
            key={`m${monthNum}`}
            value={monthNum.toString().padStart(2, "0")}
          >
            {t(`datepicker_month_${monthNum}`)}
          </option>
        )),
      [t]
    );

    const years = useMemo(() => {
      const currentYear = new Date().getFullYear();
      const tenYearsAway = currentYear + 20;
      return range(currentYear, tenYearsAway).map(year => (
        <option key={`y${year}`} value={year}>
          {year}
        </option>
      ));
    }, []);

    return (
      <>
        {label && <Form.Label>{label}</Form.Label>}
        <Form.Row>
          <Form.Group as={Col}>
            <AccessRequestSelect
              disabled={disabled}
              name={`${namePrefix}month`}
              label={false}
            >
              <OptionPlaceholder>{t("datepicker_choose")}</OptionPlaceholder>
              {months}
            </AccessRequestSelect>
          </Form.Group>
          <Form.Group as={Col}>
            <AccessRequestSelect
              disabled={disabled}
              name={`${namePrefix}year`}
              label={false}
            >
              <OptionPlaceholder>{t("datepicker_choose")}</OptionPlaceholder>
              {years}
            </AccessRequestSelect>
          </Form.Group>
        </Form.Row>
      </>
    );
  };

  const errorBox = isWaitingForFeedback(initialValues) && (
    <Alert variant="danger">{t("frform_error_returned_for_more")}</Alert>
  );

  const handleClick = (event, handleSubmit, isSubmittedForApproval) => {
    setTimeout(() => {
      formRef.current.checkValidity();
      formRef.current.reportValidity();
    }, 0);
    setTimeout(handleSubmit, 0);
    event.preventDefault();
    setSubmittedForApproval(isSubmittedForApproval);
  };

  const onSubmit = async values => {
    let submit;
    values.filesFromFeasibility = JSON.stringify(filesFromFeasibility);
    if (isSubmittedForApproval) {
      const cleanProperties = cleanPropsForNonSelectedSections(values);
      submit = dispatch(
        id
          ? updateAccessRequestForApproval(id, cleanProperties)
          : createAccessRequestForApproval(cleanProperties)
      );
    } else {
      submit = dispatch(
        id ? updateAccessRequest(id, values) : createAccessRequest(values)
      );
    }
    await submit;
    // empty the cart now
    dispatch(emptyCart());
  };

  return (
    <>
      <Title>{t("access_request")}</Title>
      <HorizontalLine className={"double-vertical-margin-bottom"} />
      <LoadingWrapper
        isLoading={isLoading}
        isInErrorState={errorPrefilling}
        errorMsg={t("form_api_offline")}
        loadingComponent={<LoadingForm />}
      >
        <Formik
          enableReinitialize={true}
          initialValues={nullsToEmptyStrings(initialValues)}
          validationSchema={schema}
          validateOnChange={false}
          onSubmit={onSubmit}
        >
          {formProps => (
            <Form onSubmit={formProps.handleSubmit} ref={formRef}>
              <UnsavedChangesPrompt isEnabled={formProps.dirty} />
              <Container>
                {errorBox}
                <Row>
                  <ContactInfoSection
                    formControl={AccessRequestFormControl}
                    disabled={true}
                  />

                  <BackgroundOrgSection
                    formControl={AccessRequestFormControl}
                  />

                  <BioBanksSection
                    formProps={formProps}
                    formControl={AccessRequestFormControl}
                    label={AccessRequestLabel}
                    checkBox={AccessRequestCheckBox}
                  />

                  <ProjectDetailsSection
                    formProps={formProps}
                    formControl={AccessRequestFormControl}
                    label={AccessRequestLabel}
                    switch={AccessRequestSwitch}
                    isAccessRequest={true}
                    disabled={disabled}
                    datePicker={AccessRequestDatePicker}
                  />

                  <MaterialSection
                    formProps={formProps}
                    formControl={AccessRequestFormControl}
                    label={AccessRequestLabel}
                    checkBox={AccessRequestCheckBox}
                  />

                  <LiquidSamplesSection
                    formProps={formProps}
                    formControl={AccessRequestFormControl}
                    label={AccessRequestLabel}
                    checkBox={AccessRequestCheckBox}
                  />

                  <TissueSamplesSection
                    formProps={formProps}
                    formControl={AccessRequestFormControl}
                    label={AccessRequestLabel}
                    checkBox={AccessRequestCheckBox}
                    isAccessRequest={true}
                  />

                  <PhenotypeLifestyleSection
                    id={id}
                    formProps={formProps}
                    formControl={AccessRequestFormControl}
                    label={AccessRequestLabel}
                    disabled={disabled}
                  />

                  <GenotypeSection
                    id={id}
                    formProps={formProps}
                    formControl={AccessRequestFormControl}
                    label={AccessRequestLabel}
                    disabled={disabled}
                  />

                  <RecruitSection
                    id={id}
                    formProps={formProps}
                    formControl={AccessRequestFormControl}
                    label={AccessRequestLabel}
                    disabled={disabled}
                  />

                  <ReturnResultsDataSection
                    formProps={formProps}
                    formControl={AccessRequestFormControl}
                    datePicker={AccessRequestDatePicker}
                    label={AccessRequestLabel}
                    radio={AccessRequestRadio}
                    disabled={disabled}
                  />

                  <InvoiceDetailsSection
                    formProps={formProps}
                    select={AccessRequestSelect}
                    formControl={AccessRequestFormControl}
                    disabled={disabled}
                  />

                  <EthicalMattersSection
                    formProps={formProps}
                    formControl={AccessRequestFormControl}
                    label={AccessRequestLabel}
                    radio={AccessRequestRadio}
                  />

                  <MandatoryDocumentsSection
                    formProps={formProps}
                    id={id}
                    disabled={disabled}
                    label={AccessRequestLabel}
                  />
                </Row>
              </Container>

              <HorizontalLine className={"top-margin"} />
              {!disabled && (
                <Container className="form-submit-row">
                  <Row>
                    <Col lg={{ span: 8, offset: 4 }}>
                      <div className={"button-group"}>
                        <button
                          className={"button button-secondary"}
                          type="submit"
                          disabled={formProps.isSubmitting}
                          onClick={event =>
                            handleClick(event, formProps.handleSubmit, false)
                          }
                        >
                          {t("save_and_continue_later")}
                        </button>
                        <button
                          disabled={formProps.isSubmitting}
                          className={"button"}
                          type="submit"
                          onClick={event =>
                            handleClick(event, formProps.handleSubmit, true)
                          }
                        >
                          {t("submit_for_approval")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              )}
            </Form>
          )}
        </Formik>
      </LoadingWrapper>
    </>
  );
};

AccessRequestForm.defaultProps = {
  initialValues
};
